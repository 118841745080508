import { BredCrumTestimonial } from "../../components/Testimonial/BredCrumTestimonial"
import Testimonials from "../../components/Testimonial/Testimonials"
import "./testimonial.css"
function TestimonialPage() {
    return <>
        <BredCrumTestimonial/>
        <Testimonials/>
    </>

}
export default TestimonialPage