import React from "react";
import Press from "../../../components/press/Press";

function PRess() {
  return (
    <div>
      <Press />
    </div>
  );
}

export default PRess