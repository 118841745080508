import React, { useState } from "react";
import "./navbar.css";
import { IoSearchSharp } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { RiContactsLine } from "react-icons/ri";
import { IoCartOutline } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { FaBars } from "react-icons/fa6";
import logo from "../../assets/swiston logo.jpg";
import flag from "../../assets/national_flag_nav.jpg";
import { Link } from "react-router-dom";
function Navbar() {
  // const [isMenuOpen, setMenuOpen] = useState(false);

  // const toggleMenu = () => {
  //   setMenuOpen(!isMenuOpen);
  // };
  return (
    <>
      <div className="navbar_background">
        <div className="nav_width">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="nav_search_flex">
                <div className="nav_product_search">
                  <input type="search" placeholder="search_products" />
                  <span>
                    <IoSearchSharp />
                  </span>
                </div>{" "}
                <div className="nav_search_pincode">
                  <p>Delivery and Store</p>
                  <div className="nav_product_search">
                    <input type="text" placeholder="Enter Pincode" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 ">
              <div className="nav_logo">
                <img src={logo} alt="" />
              </div>
            </div>
            {/* <div className="col-md-2">
              <div className="hamburger-menu" onClick={toggleMenu}>
                <div className="bar"> sdbfhb</div>
                <div className="bar">dfbsdf</div>
                <div className="bar">sdfdf</div>
              </div>
            </div> */}
            <div className="col-md-5 nav_items_cart">
              <ol className="nav_top_item">
                <li className="change_lan">
                  <span className="lan">English</span>
                  <span>
                    <IoMdArrowDropdown />
                  </span>
                  <span className="lan_flag">
                    <img src={flag} alt="" />
                  </span>
                  <span className="name">INR</span>
                </li>
                <li className="top_nav_item_log">
                  <Link to='/login'>
                    <span className="top_nav_item_icons">
                      <RiContactsLine />
                    </span>
                    <span className="top_nav_item_name">Login</span>
                  </Link>

                </li>
                <li className="top_nav_item_log">
                  {" "}
                  <Link to='/cart'>
                    <span className="top_nav_item_icons">
                      <IoCartOutline />
                    </span>
                    <span className="top_nav_item_name">My Cart</span>
                  </Link>
                </li>
                <li className="top_nav_item_log">
                  {" "}
                  <Link to='/wishList'>
                    <span className="top_nav_item_icons">
                      <FaRegHeart />
                    </span>
                    <span className="top_nav_item_name">My Wishlist</span>
                  </Link>

                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="nav_bottom_width">
          <ol className="nav_bottom_item">
            <li>
              <Link to='/category'>
                <span>RINGS</span>
                <span className="arrow_icon">
                  <IoIosArrowDown />
                </span>
              </Link>
            </li>
            <li>
              {" "}
              <span>EARRINGS</span>
              <span className="arrow_icon">
                <IoIosArrowDown />
              </span>
            </li>
            <li>
              {" "}
              <span>BRACELETS & BANGLES</span>
              <span className="arrow_icon">
                <IoIosArrowDown />
              </span>
            </li>
            <li>
              {" "}
              <span>SOLITARIES</span>
              <span className="arrow_icon">
                <IoIosArrowDown />
              </span>
            </li>
            <li>
              {" "}
              <span>MANGALSTRAS</span>
              <span className="arrow_icon">
                <IoIosArrowDown />
              </span>
            </li>
            <li>
              <span>NECKLACE</span>
              <span className="arrow_icon">
                <IoIosArrowDown />
              </span>{" "}
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}

export default Navbar