import React from "react";
import JewellaryEdit from "../../components/press/jewellaryEdit/JewellaryEdit";

function Jewellaryedit() {
  return (
    <div>
      <JewellaryEdit />
    </div>
  );
}

export default Jewellaryedit