import React from "react";
import { FiPhoneCall } from "react-icons/fi";
function ContactContent({ contactIcon, contactHeading, ContactName }) {
  return (
    <>
      <div className="col-md-4 contact_flex">
        <div className="contact_icon">
          <span>{contactIcon}</span>
        </div>
        <div className="contact_content">
          <p className="heading">{contactHeading}</p>
          <p className="name">{ContactName}</p>
        </div>
      </div>
    </>
  );
}

export default ContactContent;
