import React from "react";
import "../serviceTileSection/serviceTileSection.css";
function ServiceTileSection() {
  return (
    <>
      <div className="service-tile-section"></div>
    </>
  );
}

export default ServiceTileSection;
