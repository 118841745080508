import React from "react";

const ListCompo = ({ data }) => {
  return (
    <>
      <li>{data}</li>
    </>
  );
};

export default ListCompo;
