
import { StoreBox1 } from './StoreBox1';
import { StoreBox2 } from './StoreBox2';
export const Storeques = () => {
    return <section className="sec-store">
        <div className="box-store">
            <StoreBox1 />
            <StoreBox2 />
        </div>
    </section>
}