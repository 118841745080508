function OurStores() {
    return <div>
        <div className="stors">
            <h3>Our stores near you</h3>
            <p>Enter your PIN code to find stores near you</p>
        </div>
        <div>
            <form className="formdNa">
                <div>
                    <input className="form-control" placeholder="Enter Pincode" />
                    <button type="button" className="btn btn-info" style={{margin:"10px 0"}}>Locate Me</button>
                </div>
                <button className="et0l4465 css-wuehcv e1jmj0hg0">VIEW STORES</button>
            </form>
        </div>
    </div>
}
export default OurStores