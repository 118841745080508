import React, { useEffect } from 'react'
import TrackOrder from '../../components/track-order/TrackOrder'

function TrackOrderPage({setShow}) {

  return (
    <>
        <TrackOrder />
    </>
  )
}

export default TrackOrderPage