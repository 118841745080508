export const BreansStory = () => {
    return <div>
        <h2 className="css-rbca22">Featured In</h2>
        <div className="css-e9q2w5">
            <ul>
                <li>
                    <img
                        src="https://cdn1.caratlane.com/static/images/V4/2018/AboutusPages/our_Story/new_site/Verve.png"
                        alt=""
                    />
                </li>
                <li>
                    <img
                        src="https://cdn1.caratlane.com/static/images/V4/2018/AboutusPages/our_Story/new_site/Forbes.png"
                        alt=""
                    />
                </li>
                <li>
                    <img
                        src="https://cdn1.caratlane.com/static/images/V4/2018/AboutusPages/our_Story/new_site/Femina.png"
                        alt=""
                    />
                </li>
                <li>
                    <img
                        src="https://cdn1.caratlane.com/static/images/V4/2018/AboutusPages/our_Story/new_site/Vogue.png"
                        alt=""
                    />
                </li>
                <li>
                    <img
                        src="https://cdn1.caratlane.com/static/images/V4/2018/AboutusPages/our_Story/new_site/GQ.png"
                        alt=""
                    />
                </li>
            </ul>
        </div>
    </div>

}