import Card from "./card/Card"

const Cart = () => {
  return (

    <div className="h100 ">
      <Card />
    </div>

  )
}
export default Cart 