
import BredCrumvedio from "../../components/vedio-gallery/BredCrumvedio"
import { Vediogallery } from "../../components/vedio-gallery/Vediogallery"
import "./vediogall.css"
function VedioGallerypage() {
    return <>
        <BredCrumvedio/>
        <Vediogallery/>
    </>

}
export default VedioGallerypage