import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'

const ButtonAndKnow = () => {
  return (
    <div className='pt-5 pb-5'>
         <div className=' btngap  '> <div><button className='btn1_1'>Buy Digital Gold</button></div> 
            <div ><button className='learnmore text-dark'>LEARN MORE <span> <IoIosArrowDown /> </span></button></div>
            </div>
    </div>
  )
}

export default ButtonAndKnow