import { MidleCArd } from "./MidleCArd"

export const MiddleStory = ()=>{
    return <div className="css-ype3f5">
    <div id="caratlane-advantages" className="css-158ycgb">
      <div className="container">
        <div className="css-efntk">
         <MidleCArd/>
         <MidleCArd/>
         <MidleCArd/>
         <MidleCArd/>
         
        </div>
      </div>
    </div>
  </div>
  
}