import React from "react";
import "./copyRights.css";
import { Link } from "react-router-dom";
function CopyRight() {
  return (
    <>
      <div className="copyright_background">
        <div className="copyright_width">
          <div className="copyright">
            Copyright © 2024
            <Link to="https://www.abarissoftech.com/">
              <span>Abaris Softech Pvt Ltd.</span>
            </Link>
            All rights reserved.
          </div>
          <div className="copyItem">
            <p>Terms to Use</p>
            <p>Privacy Environmental Policy</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CopyRight;
