import React from "react";
import WebStories from "../../components/press/webStories/WebStories";

function Webstories() {
  return (
    <div>
      <WebStories />
    </div>
  );
}

export default Webstories