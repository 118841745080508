export const BredCrum = () => {
    return <div className="bredcrum">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h4>YOUR WISHLIST  </h4>
                    <p>HOME > WISHLIST</p>
                </div>
            </div>
        </div>
    </div>
}
export default BredCrum