import React from "react";
import logo from "../../assets/swiston logo.jpg";
import "./footers.css";
import ListCompo from "../../common/sharedCompo/ListCompo";
import ContactContent from "./contactContent/ContactContent";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineAttachEmail } from "react-icons/md";
import { TiLocation } from "react-icons/ti";
import FooterAboutItem from "./footerAboutItem/FooterAboutItem";
import CopyRight from "./copyRights/CopyRight";
const dataItem = [
  { id: 1, title: "Home" },
  { id: 2, title: "About Us" },
  { id: 3, title: "News" },
  { id: 4, title: "Gallery" },
  { id: 5, title: "Contact" },
];
const contactItem = [
  {
    id: 1,
    contactIcon: <FiPhoneCall />,
    contactHeading: "Phone Number",
    ContactName: "+91 123456789",
  },
  {
    id: 2,
    contactIcon: <MdOutlineAttachEmail />,
    contactHeading: "Email Address",
    ContactName: "info@swistonjewellery.com",
  },
  {
    id: 3,
    contactIcon: <TiLocation />,
    contactHeading: "Office Address",
    ContactName: "14/A,Marina City,New Delhi",
  },
];
function Footers() {
  return (
    <>
      <div className="footer_backgrund_image">
        <div className="footer_background_color">
          <div className="footer_background_width">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer_logo">
                  <img src={logo} alt="" />
                </div>
                <ol className="footer_item_flex">
                  {dataItem.map((item) => (
                    <ListCompo data={item.title} />
                  ))}
                </ol>
              </div>
              <div className="col-lg-8"></div>
            </div>
            <div className="row contact_margin">
              {contactItem.map((item) => (
                <ContactContent
                  contactIcon={item.contactIcon}
                  contactHeading={item.contactHeading}
                  ContactName={item.ContactName}
                />
              ))}
            </div>
          </div>
          <div className="">
            <hr className="border border-1 border-secondary" />
          </div>
          <FooterAboutItem />
        </div>
      </div>
      <CopyRight />
    </>
  );
}

export default Footers