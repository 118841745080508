import React from "react";
import "./footerAboutItem.css";
// import ListCompo from "../../../components/";

const aboutHeading = [
  {
    id: 1,
    heading: "Customer Service",
    child: [
      { id: 1, itemName: "Return Policy" },
      { id: 2, itemName: "Order Status" },
    ],
  },
  {
    id: 2,
    heading: "About Us",
    child: [
      { id: 1, itemName: "Our Story" },
      { id: 2, itemName: "Press" },
      { id: 3, itemName: "Blog" },
      { id: 4, itemName: "Careers" },
      { id: 5, itemName: "Testimonical" },
      { id: 6, itemName: "Video Gallery" },
      { id: 7, itemName: "Photo Gallery" },
      { id: 8, itemName: "Contact Us" },
    ],
  },
  {
    id: 3,
    heading: "Customer Service",
    child: [
      { id: 1, itemName: "15-Day Returns" },
      { id: 2, itemName: "Free Shipping" },
      { id: 3, itemName: "Financing Options" },
      { id: 4, itemName: "Old Gold Exchange" },
    ],
  },
  {
    id: 4,
    heading: "Swiston Advantage",
    child: [
      { id: 1, itemName: "Return Policy" },
      { id: 2, itemName: "Order Status" },
    ],
  },
  {
    id: 5,
    heading: "Contact Us",
    child: [
      { id: 1, itemName: "24X7 Enquiry Support(All Days)" },
      { id: 2, itemName: "General : @swiston.com" },
      { id: 3, itemName: "Corporate : @swiston.com" },
      { id: 4, itemName: "HR : Swiston.com" },
    ],
  },
];
function FooterAboutItem() {
  return (
    <>
      <div className="footer_background_width">
        <div className="row m-0 p-0">
          {aboutHeading.map((item) => (
            <div className="col-lg-2 col-md-4 col-sm-6 footer_about_content p-0">
              <h6 className="heading">{item.heading}</h6>
              <ol className="items">
                {Array.isArray(item.child) &&
                  item.child.map((item2) => <div>{item2.itemName}</div>)}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default FooterAboutItem;
